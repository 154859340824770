import { useCallback, useEffect, useRef } from 'react';

export function useAdsgram({ blockId, onReward, onError }) {
  const AdControllerRef = useRef(null);

  useEffect(() => {
    if (window.Adsgram) {
      AdControllerRef.current = window.Adsgram.init({ blockId });
    }
  }, [blockId]);

  return useCallback(async () => {
    if (AdControllerRef.current) {
      try {
        await AdControllerRef.current.show();
        onReward();
      } catch (error) {
        onError(error);
      }
    } else {
      onError({ error: true, description: 'AdsGram script not loaded' });
    }
  }, [onError, onReward]);
}
