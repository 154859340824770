import React, { useEffect, useRef, useState } from "react";
import { collection, updateDoc, doc, addDoc, getDocs} from 'firebase/firestore';
import { increment } from "firebase/firestore";
import Animate from "../Components/Animate";
import { Outlet } from "react-router-dom";
import { Address } from "../Components/WalletDetails";
import { useUser } from "../context/userContext";
import { PiNotebook } from "react-icons/pi";  // Assuming you use these icons
import { FaBoxes } from "react-icons/fa";
import { IoCheckmarkCircleSharp } from "react-icons/io5"; // Assuming this is the correct icon for the modal
import { IoCloseSharp } from "react-icons/io5";  // X icon
import { IoWarningSharp } from "react-icons/io5"; 
import { db } from '../firebase/firestore'; // Import Firestore configuration
import {  useTonConnectUI } from '@tonconnect/ui-react';

const Wallet = () => {
  const { id, balance, setBalance, openInfoTwo, setOpenInfoTwo, tonBalance, setTONBalance, walletAddress} = useUser();
  const [swapAmount, setSwapAmount] = useState('');
  const [tonEquivalent, setTonEquivalent] = useState(0);
  const [activeIndex, setActiveIndex] = useState(2); // Default is Swap active
  const [modalOpen, setModalOpen] = useState(false);
  const infoRefTwo = useRef(null);
  const [SwapMessage, setSwapMessage] = useState(''); // Swap message
  const [image, setImage] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [tonConnectUI] = useTonConnectUI();
  const [transactionStatus, setTransactionStatus] = useState("Pending");
  const [loadingTrx, setLoadingTrx] = useState(false);
  
  const handleClickOutside = (event) => {
    if (infoRefTwo.current && !infoRefTwo.current.contains(event.target)) {
      setOpenInfoTwo(false);
    }
  };

  const handleMenu = (index) => {
    setActiveIndex(index);
  };

  // Fetch transactions for the current user from Firestore
  const fetchTransactions = async () => {
    setLoadingTrx(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'transactions'));
      const transactionList = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.user === walletAddress) {
          transactionList.push({ id: doc.id, ...data });
        }
      });
      setTransactionHistory(transactionList);
    } catch (error) {
      console.error('Error fetching transactions: ', error);
    }
    setLoadingTrx(false);
  };

  // Retrieve transactions from Firebase on component mount
  useEffect(() => {
    if (activeIndex === 3) {
      // Fetch transactions when the Transaction History tab is active
      fetchTransactions();
    }
  }, [activeIndex]); // Only run when activeIndex changes

  useEffect(() => {
    if (openInfoTwo) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openInfoTwo]);

  // Function to handle swap amount input change
const handleSwapAmountChange = (e) => {
  const value = e.target.value;
  setSwapAmount(value);
  setTonEquivalent(value / 10000000); // Calculate TON equivalent (Example: 10 million iTon = 1 TON)
};

// Main swap function
const handleSwap = async () => {
  const swapAmountValue = parseFloat(swapAmount); // Parse swapAmount to a float
  const TonEqu = parseFloat(tonEquivalent); // Parse tonEquivalent to a float

  // Validate the swapAmount
  if (isNaN(swapAmountValue) || swapAmountValue <= 0) {
    setImage(<IoCloseSharp size={60} className="text-red-500" />);
    setSwapMessage("Invalid input, please enter a valid number greater than 0.");
    setModalOpen(true);
    return;
  }

  if (swapAmountValue > balance) {
    setImage(<IoCloseSharp size={60} className="text-red-500" />);
    setSwapMessage("Swap Failed! You have insufficient iTon balance.");
    setModalOpen(true);
    return;
  }

  if (swapAmountValue < 100) {
    setImage(<IoCloseSharp size={60} className="text-red-500" />);
    setSwapMessage("To make the swap, you need a minimum of 100 iTon.");
    setModalOpen(true);
    return;
  }

  // Optimistic UI update: update balance and tonBalance
  setTONBalance(prevTONBalance => prevTONBalance + TonEqu);
  setBalance(prevBalance => prevBalance - swapAmountValue);

  // Show success message
  setImage(<IoCheckmarkCircleSharp size={60} className="text-green-500" />);
  setSwapMessage(`You have successfully swapped ${swapAmountValue} iTon for ${TonEqu.toFixed(6)} TON.`);
  setModalOpen(true);

  try {
    // Real database update
    const userDocRef = doc(db, 'telegramUsers', id);
    await updateDoc(userDocRef, {
      balance: increment(-swapAmountValue),   // Reduce balance
      tonBalance: increment(TonEqu),          // Increase tonBalance
    });

    console.log('Database updated successfully');
  } catch (error) {
    console.error('Error updating database: ', error);

    // Revert the UI updates if database update fails
    setTONBalance(prevTONBalance => prevTONBalance - TonEqu);
    setBalance(prevBalance => prevBalance + swapAmountValue);

    setImage(<IoCloseSharp size={60} className="text-red-500" />);
    setSwapMessage("Failed to update balances in the database. Please try again.");
    setModalOpen(true);
  }
};

const toncenterAPI = "https://toncenter.com/api/v2";
const feeWalletAddress = "UQAB-FGlJ-hh0Uvai0FtnTo6Z4AGB0_gGHKDDmPtTuHiN3fJ"; // Fee wallet

const saveTransaction = async (transaction) => {
  try {
    await addDoc(collection(db, "transactions"), transaction);
  } catch (error) {
    console.error("Error saving transaction: ", error);
  }
};

// Function to verify payment using TonCenter API
const verifyPayment = async (transactionId, walletAddress) => {
  try {
    const response = await fetch(`${toncenterAPI}/getTransactions?address=${feeWalletAddress}&limit=10`);
    const data = await response.json();
    const transactions = data.result;

    // Check if the user sent the 0.20 TON
    const payment = transactions.find(tx => 
      tx.in_msg.source === walletAddress && tx.in_msg.value === "200000000" // 0.20 TON in nanoTON
    );

    if (payment) {
      // Payment is verified, update the transaction status
      setTransactionStatus("Completed");

      // Update the transaction in the database
      const transactionRef = doc(db, "transactions", transactionId);
      await updateDoc(transactionRef, {
        status: "Completed",
        verifiedAt: new Date().toISOString()
      });

      // Deduct the withdrawal amount from the user's tonBalance
      setTONBalance(prevTONBalance => prevTONBalance - withdrawAmount);

      console.log('Transaction verified and balance updated.');
      setImage(<IoCheckmarkCircleSharp size={60} className="text-green-500" />);
      setSwapMessage('Transaction Verified Successfully \n you will recieve your TON balance on your connected wallet.');
      setModalOpen(true);
      
    } else {
      // Payment not found or not verified
      setTransactionStatus("Pending");
      console.log('Payment not verified yet.');
      setImage(<IoWarningSharp size={60} className="text-yellow-500" />);
      setSwapMessage('Payment Under Verification. \n don\'t worry your TON balance is still safe!');
      setModalOpen(true);
    }
  } catch (error) {
    console.error("Error verifying payment: ", error);
    setTransactionStatus("Error");
  }
};

// Function to handle the withdrawal process
const handleWithdrawWithDeepLink = async () => {
  if (withdrawAmount <= 0) {
    setImage(<IoWarningSharp size={60} className="text-yellow-500" />);
    setSwapMessage("Please enter an amount greater than 0.");
    setModalOpen(true);
    return;
  }

  if (withdrawAmount > tonBalance) {
    setImage(<IoWarningSharp size={60} className="text-yellow-500" />);
    setSwapMessage("Insufficient TON balance. Earn iTon coins by completing tasks, and swap your iTon with TON.");
    setModalOpen(true);
    return;
  }

  if (withdrawAmount < 1) {
    setImage(<IoWarningSharp size={60} className="text-yellow-500" />);
    setSwapMessage("Minimum Withdrawal limit is 1 TON. Earn more iTon to have more TON coins by completing tasks, and swap your iTon with TON.");
    setModalOpen(true);
    return;
  }

  // Add the transaction to history as pending
  const newTransaction = {
    date: new Date().toLocaleDateString(),
    amount: withdrawAmount,
    status: "Pending",
    user: walletAddress,  // Save the user's wallet address
    userId: id,
  };

  try {
    // Save the transaction in the 'transactions' collection
    const transactionRef = await addDoc(collection(db, "transactions"), newTransaction);
    const transactionId = transactionRef.id;

    // Update the user's tonBalance in the 'telegramUsers' collection
    const userRef = doc(db, 'telegramUsers', id);
    await updateDoc(userRef, {
      tonBalance: tonBalance - withdrawAmount, // Deduct the withdrawal amount from the user's balance
    });

    setTONBalance(tonBalance - withdrawAmount); // Update UI tonBalance immediately

    // Trigger TonConnect UI for the fee payment
    await tonConnectUI.sendTransaction({
      validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes expiry
      messages: [
        {
          address: feeWalletAddress,
          amount: "200000000", // 0.20 TON in nanoTONs
          text: "Withdrawal Fee",
        },
      ],
    });

    // After sending the transaction, verify payment
    await verifyPayment(transactionId, walletAddress);

  } catch (error) {
    console.error("Error processing withdrawal: ", error);
    setImage(<IoCloseSharp size={60} className="text-red-500" />);
    setSwapMessage("Withdraw Cancelled! \n operation failed. Please try again.");
    setModalOpen(true);
  }

  setWithdrawAmount(0); // Clear input after submission
};

  // Clear both inputs
  const clearInputs = () => {
    setSwapAmount('');
    setTonEquivalent('');
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat().format(num);
  };

  return (
    <>
      <Animate>
        <div className="w-full pt-8 flex items-center justify-center flex-col space-y-3 px-5">
        <div className="w-full flex justify-center items-center flex-col space-y-3">
        <div className="w-full flex justify-between items-center bg-[#1c1c1e] p-4 rounded-lg">
  {/* Left Side: Balances */}
  <div className="flex flex-col text-left">
    <div className="text-xs text-gray-400">
      iTon: {formatNumber(balance)}
    </div>
    <div className="text-xs text-gray-400 mt-1">
      TON: {formatNumber(tonBalance)}
    </div>
  </div>

  {/* Right Side: Address */}
  <div className="text-right">
    <Address className="text-gray-400 text-sm" />
  </div>
</div>

          </div>

          {/* Menu Buttons */}
          <div className="w-full flex items-center justify-between mt-4">
            <div
              onClick={() => handleMenu(1)}
              className={`${
                activeIndex === 1 ? 'bg-cards text-[#ebebeb]' : ''
              } rounded-[6px] text-primary py-[10px] px-3 w-[30%] flex space-x-2 justify-center text-center text-[15px] font-semibold items-center cursor-pointer`}
            >
              <PiNotebook size={16} />
              <span>Withdraw</span>
            </div>

            <div
              onClick={() => handleMenu(2)}
              className={`${
                activeIndex === 2 ? 'bg-cards text-[#ebebeb]' : ''
              } rounded-[6px] text-primary py-[10px] px-3 w-[30%] space-x-2 font-semibold text-[15px] flex justify-center text-center items-center cursor-pointer`}
            >
              <FaBoxes size={16} />
              <span>Swap</span>
            </div>

            <div
              onClick={() => handleMenu(3)}
              className={`${
                activeIndex === 3 ? 'bg-cards text-[#ebebeb]' : ''
              } rounded-[6px] text-primary py-[10px] px-3 w-[30%] flex space-x-2 justify-center text-center text-[15px] font-semibold items-center cursor-pointer`}
            >
              <PiNotebook size={16} />
              <span>Transaction</span>
            </div>
          </div>
          <div id="refer" className="w-full h-[60vh] scroller rounded-[10px] overflow-y-auto pt-2 pb-[180px]">
          {/* Withdraw Feature */}
{activeIndex === 1 && (
  <div className="w-full flex flex-col items-center mt-8 bg-[#1c1c1e] rounded-lg p-4 shadow-md text-white">
    <h2 className="text-xl font-bold mb-6">Withdraw TON</h2>

    {/* Enter Amount Section */}
    <div className="w-full mb-4">
      <div className="flex justify-between mb-1">
        <span className="text-xs text-gray-400">Enter amount</span>
        <span className="text-xs text-gray-400">Available: {formatNumber(tonBalance)} TON</span>
      </div>
      <div className="flex items-center justify-between bg-[#2c2c2e] p-3 rounded-md">
        <input
          type="number"
          value={withdrawAmount}
          onChange={(e) => setWithdrawAmount(e.target.value)}
          placeholder="0.00"
          className="text-right text-lg font-semibold bg-transparent outline-none w-full"
        />
      </div>
    </div>

    {/* Withdraw Button */}
<button
  onClick={handleWithdrawWithDeepLink}
  className="bg-btn4 text-white font-semibold text-[15px] rounded-md w-full py-3"
>
  Withdraw TON
</button>

    {/* Footer */}
    <p className="text-sm mt-2 mb-4">Note: Withdrawal status will be marked as "Pending" until processed.</p>
    </div>
          )}

          {/* Swap Feature */}
          {activeIndex === 2 && (
              <div className="w-full flex flex-col items-center justify-center space-y-4 mt-8 bg-[#1c1c1e] rounded-lg p-4 shadow-md text-white">
                <h2 className="text-xl font-bold mb-6">Wallet Assets</h2>

                {/* From Section */}
                <div className="w-full mb-4">
                  <div className="flex justify-between mb-1">
                    <span className="text-xs text-gray-400">From</span>
                    <span className="text-xs text-gray-400">
                      Available: {formatNumber(balance)} INSTANT
                    </span>
                  </div>
                  <div className="flex items-center justify-between bg-[#2c2c2e] p-3 rounded-md">
                  
                    <div className="flex items-center">
                      <img src="/INST.webp" alt="iTon Icon" className="w-6 h-6 mr-2" />
                      <div>
                        <span className="font-medium">iTon</span>
                        <p className="text-xs text-gray-400">Instant Token</p>
                      </div>
                    </div>
                    <input
                      type="number"
                      value={swapAmount}
                      onChange={handleSwapAmountChange}
                      placeholder="0.00"
                      className="text-right text-lg font-semibold bg-transparent outline-none w-24"
                    />
                    <div className="text-right mt-1 text-sm text-yellow-500 cursor-pointer" onClick={clearInputs}>
          Clear
        </div>
                  </div> 
                </div>

                {/* To Section */}
                <div className="w-full mb-4">
                  <div className="flex justify-between mb-1">
                    <span className="text-xs text-gray-400">To</span>
                  </div>
                  <div className="flex items-center justify-between bg-[#2c2c2e] p-3 rounded-md">
                    <div className="flex items-center">
                      <img src="/ton.webp" alt="TON Icon" className="w-6 h-6 mr-2" />
                      <div>
                        <span className="font-medium">TON</span>
                        <p className="text-xs text-gray-400">Toncoin</p>
                      </div>
                    </div>
                    <input
                      type="number"
                      value={tonEquivalent}
                      readOnly
                      placeholder="0.00"
                      className="text-right text-lg font-semibold bg-transparent outline-none w-24"
                    />
                  </div>
                </div>

                {/* Swap Button */}
                <button
                  onClick={handleSwap}
                  className="bg-btn4 text-[#ffffff] font-semibold text-[15px] rounded-md w-full py-3"
                >
                  Swap iTon for TON
                </button>

                {/* Footer */}
                <p className="text-sm mt-2 mb-4">10 Million INSTANT ≈ 1 TON</p>
              </div>
          )}

           {/* Transaction History */}
      {activeIndex === 3 && (
        <div className="w-full mt-8 bg-[#1c1c1e] rounded-lg p-4 shadow-md text-white">
          <h3 className="text-lg font-bold mb-4">Transaction History</h3>

          {loadingTrx ? (
            <p>Loading transactions...</p>
          ) : transactionHistory.length === 0 ? (
            <p>No transactions found.</p>
          ) : (
            transactionHistory.map((transaction, index) => (
              <div key={index} className="flex justify-between mb-2">
                <span>{transaction.date}</span>
                <span>{transaction.amount} TON</span>
                <span>{transaction.status}</span>
              </div>
            ))
          )}
  </div>
)}
    </div>
        </div>
      </Animate>

      {/* Swap Modal */}
      <div
        className={`${
          modalOpen === true ? "visible" : "invisible"
        } fixed top-[-12px] bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4`}
      >
  

    <div className={`${
          modalOpen === true ? "opacity-100 mt-0 ease-in duration-300" : "opacity-0 mt-[100px]"
        } w-full bg-modal relative rounded-[16px] flex flex-col justify-center p-8`}>
          <div className="w-full flex justify-center flex-col items-center space-y-3">
            <div className="w-full items-center justify-center flex flex-col space-y-2">
              {image}
            </div>
            <h3 className="font-medium text-[20px] text-[#ffffff] pt-2 pb-2">
              <span className={`text-accent`}>+{SwapMessage}</span>
            </h3>
          </div>

          <div className="w-full flex justify-center">
            <button
              onClick={closeModal}
              className={`bg-btn4 w-fit py-[10px] px-6 flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]`}
            >
             Back To Assets
            </button>
          </div>
        </div>
      </div>

      <Outlet />
    </>
  );
};

export default Wallet;


