import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firestore'; // adjust the path as needed
import { collection, updateDoc, doc, getDoc, getDocs } from "firebase/firestore"; 
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { PiArrowRight } from 'react-icons/pi';
import Spinner from './Spinner';
import { PiNotebook } from "react-icons/pi";

const linksTo = [
  {
    link: '/dashboardAdx/managetasks',
    title: 'Telegram Tasks',
  },
  {
    link: '/dashboardAdx/externaltasks',
    title: 'External Tasks',
  },
  {
    link: '/dashboardAdx/search',
    title: 'Search user',
  },
  {
    link: '/dashboardAdx/settings',
    title: 'Settings',
  },
]; 

const StatisticsPanel = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalTapBalance, setTotalTapBalance] = useState(0);
  const [activeUsersLast24Hours, setActiveUsersLast24Hours] = useState(0);
  const [activeUsersLast1Hour, setActiveUsersLast1Hour] = useState(0);
  const [activeUsersLast1Minute, setActiveUsersLast1Minute] = useState(0);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(2); 
  const [transactions, setTransactions] = useState([]);
  const [loadingTrx, setLoadingTrx] = useState(false);

  const handleMenu = (index) => {
    setActiveIndex(index);
  };

  const fetchTransactions = async () => {
    setLoadingTrx(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'transactions'));
      const transactionList = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.status === 'Pending') {
          transactionList.push({ id: doc.id, ...data });
        }
      });
      setTransactions(transactionList);
    } catch (error) {
      console.error('Error fetching transactions: ', error);
    }
    setLoadingTrx(false);
  };

  const markTransactionAsCompleted = async (transactionId) => {
    try {
      const transactionRef = doc(db, 'transactions', transactionId);
      await updateDoc(transactionRef, {
        status: 'Completed',
        completedAt: new Date().toISOString(),
      });
      console.log('Transaction status updated to Completed.');
      fetchTransactions(); // Refresh transaction list after completion
    } catch (error) {
      console.error('Error updating transaction: ', error);
    }
  };

  const cancelTransaction = async (transactionId, userId, withdrawAmount) => {
    try {
      const transactionRef = doc(db, 'transactions', transactionId);
      const userRef = doc(db, 'telegramUsers', userId);

      // Fetch the user's current tonBalance
      const userSnapshot = await getDoc(userRef); 
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        const updatedBalance = userData.tonBalance + withdrawAmount; 

        // Update the transaction status to 'Cancelled'
        await updateDoc(transactionRef, {
          status: 'Cancelled',
          cancelledAt: new Date().toISOString(),
        });

        // Update the user's tonBalance
        await updateDoc(userRef, {
          tonBalance: updatedBalance,
        });

        console.log('Transaction status updated to Cancelled and balance refunded.');
        fetchTransactions(); 
      } else {
        console.error('User not found');
      }
    } catch (error) {
      console.error('Error canceling transaction: ', error);
    }
  };

  useEffect(() => {
    fetchTransactions();
    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    const last24Hours = moment().subtract(24, 'hours').toDate();
    const last1Hour = moment().subtract(1, 'hour').toDate();
    const last1Minute = moment().subtract(1, 'minute').toDate();

    const usersQuery = collection(db, "telegramUsers");
    const querySnapshot = await getDocs(usersQuery);
    const usersData = querySnapshot.docs.map(doc => doc.data());

    setTotalUsers(usersData.length);
    setTotalBalance(usersData.reduce((acc, user) => acc + (user.balance || 0), 0));
    setTotalTapBalance(usersData.reduce((acc, user) => acc + (user.tapBalance || 0), 0));
    setActiveUsersLast24Hours(usersData.filter(user => user.lastActive && user.lastActive.toDate() > last24Hours).length);
    setActiveUsersLast1Hour(usersData.filter(user => user.lastActive && user.lastActive.toDate() > last1Hour).length);
    setActiveUsersLast1Minute(usersData.filter(user => user.lastActive && user.lastActive.toDate() > last1Minute).length);
    setLoading(false);
  };

  const formatNumber = (num) => {
    if (isNaN(num)) return "Invalid number";
    return num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 6 });
  };

  const statista = [
    { title: 'Total Users', count: totalUsers },
    { title: 'Total Balance', count: formatNumber(totalBalance) },
    { title: 'Total Taps', count: formatNumber(totalTapBalance) },
    { title: 'Last 24hours', count: activeUsersLast24Hours },
    { title: 'Last 1hour', count: activeUsersLast1Hour },
    { title: 'Online Users', count: activeUsersLast1Minute },
  ];

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col space-y-4 h-[100vh] scroller pt-4 overflow-y-auto pb-[150px]">
          <div className="w-full flex justify-start items-start flex-wrap gap-4">
            {statista.map((stats, index) => (
              <div key={index} className="bg-cards p-4 rounded-[10px] w-[47%] sm:w-[32%] h-[120px] flex flex-col justify-center space-y-3">
                <h2 className="text-[16px] sm:text-[18px] font-semibold text-[#bdbdbd]">{stats.title}</h2>
                <span className='text-[20px] sm:text-[24px] text-[#fff] font-bold'>{stats.count}</span>
              </div>
            ))}
          </div>

          <h2 className='font-semibold text-[17px] pt-3'>Admin Control Items</h2>

          <div className='flex flex-col space-y-4 w-full'>
            {linksTo.map((menu, index) => (
              <NavLink to={menu.link} key={index} className="bg-cards px-4 py-4 flex rounded-[6px] justify-between items-center space-x-1 font-medium">
                <span>{menu.title}</span>
                <span><PiArrowRight size={16} /></span>
              </NavLink>
            ))}

            <div
              onClick={() => handleMenu(3)} 
              className="rounded-[6px] text-primary py-[10px] px-3 w-full flex space-x-2 justify-center text-center text-[15px] font-semibold items-center cursor-pointer"
            >
              <PiNotebook size={16} />
              <span>Transaction</span>
            </div>
          </div>

          {activeIndex === 3 && (
            <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-90 z-50 flex justify-center items-center overflow-auto">
              <div className="w-full max-w-6xl bg-[#1c1c1e] p-8 rounded-lg shadow-lg">
                <h2 className="text-xl font-bold mb-6">Pending Transactions</h2>
                {transactions.length === 0 ? (
                  <p>No pending transactions available.</p>
                ) : (
                  <table className="min-w-full bg-[#1c1c1e] text-gray rounded-lg shadow-md">
                    <thead>
                      <tr>
                        <th className="py-2 px-4 border-b">Date</th>
                        <th className="py-2 px-4 border-b">Amount</th>
                        <th className="py-2 px-4 border-b">User Address</th>
                        <th className="py-2 px-4 border-b">User ID</th>
                        <th className="py-2 px-4 border-b">Status</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((trx) => (
                        <tr key={trx.id}>
                          <td className="py-2 px-4 border-b">{moment(trx.createdAt).format('YYYY-MM-DD')}</td>
                          <td className="py-2 px-4 border-b">{formatNumber(trx.amount)} TON</td>
                          <td className="py-2 px-4 border-b">{trx.userAddress || 'Address not available'}</td>
                          <td className="py-2 px-4 border-b">{trx.userId}</td>
                          <td className="py-2 px-4 border-b">{trx.status}</td>
                          <td className="py-2 px-4 border-b">
                            <button
                              className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-500"
                              onClick={() => markTransactionAsCompleted(trx.id)}
                            >
                              Mark Completed
                            </button>
                            <button
                              className="bg-red-600 text-white px-4 py-2 rounded ml-4 hover:bg-red-500"
                              onClick={() => cancelTransaction(trx.id, trx.userId, trx.amount)}
                            >
                              Cancel Transaction
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default StatisticsPanel;



